import React, { useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import logo from '@assets/image/logo.jpg';
import * as event from './event';
import styles from './RetailBillVertical.module.scss';
import { shopConfig } from '@/config/shop-config';

function RetailBill({ bill }) {
  const [today, setToday] = useState(new Date());
  const [bills, setBills] = useState([
    {
      product: bill.product.name,
      count: 1,
      price: bill.price,
      total: bill.price,
    },
    ...Array(9)
      .fill('')
      .map(() => ({
        product: '',
        count: '',
        price: '',
        total: '',
      })),
  ]);

  const [discount, setDiscount] = useState(0);

  const [feeDelivery, setFeeDelivery] = useState(0);

  const [VAT, setVAT] = useState(0);

  const prevTotal = React.useMemo(
    () => _.sumBy(bills, (bill) => bill.total || 0),
    [bills],
  );

  const tax = React.useMemo(
    () => Math.ceil((prevTotal * VAT) / 100, [prevTotal, VAT]),
    [VAT, prevTotal],
  );

  const prevDiscount = React.useMemo(
    () => prevTotal + feeDelivery + tax,
    [prevTotal, feeDelivery, tax],
  );

  const prevDiscountWithPaid = React.useMemo(
    () => prevDiscount - bill.paid,
    [prevDiscount, bill],
  );

  const total = React.useMemo(
    () => prevDiscount - discount,
    [prevDiscount, discount],
  );

  const totalPayment = React.useMemo(() => total - bill.paid, [total, bill]);

  const handleDiscountChange = (e) => {
    const value = e.target.value.replace(/\./g, '');

    if (value.search(/[^0-9]/g) === -1) {
      const numValue = value ? parseInt(value) : 0;
      numValue < prevDiscountWithPaid
        ? setDiscount(numValue)
        : setDiscount(prevDiscountWithPaid);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.contactHeader}>
          <h3 className={styles.companyName}>Công ty Khắc dấu quốc tiến</h3>
          <h4>
            MST: <span className={styles.tax}>0316398073 - </span>
            <span className={styles.hotline}>Hotline: 0933339833</span>
          </h4>
          <p>VPKD: {shopConfig.address}</p>
        </div>

        <div className={styles.logoWrapper}>
          <img src={logo} alt="" />
        </div>
      </div>

      <div className={styles.heading}>
        <h3 className={styles.titleHeader}>Hóa đơn bán hàng</h3>
        <h4 className={styles.noHeader}>
          Số: <span>{bill.id}</span>
        </h4>
      </div>

      <div className={styles.receiver}>
        <div>
          <span className={styles.receiverHeader}>Khách hàng:</span>
          <span className={styles.receiverContent} contentEditable>
            {bill.name} - {bill.phones.join(' - ')}
          </span>
        </div>

        <div className={styles.receiverWrapper}>
          <span className={clsx(styles.receiverHeader, styles.receiverAddress)}>
            Địa chỉ:
          </span>
          <span className={styles.receiverContent} contentEditable>
            {bill.address}
          </span>
        </div>
      </div>

      <div className={styles.tableHeader}>
        <div className={styles.tableCell}>Sản phẩm</div>
        <div className={styles.tableCell}>SL</div>
        <div className={styles.tableCell}>Đơn giá</div>
        <div className={styles.tableCell}>Thành tiền</div>
      </div>

      <div className={styles.tableContent}>
        {bills.map((bill, index) => (
          <div className={styles.tableRow} key={index}>
            <input
              className={styles.tableCell}
              value={bill.product}
              onChange={(e) => event.handleProductChange(e, index, setBills)}
            />
            <input
              className={styles.tableCell}
              value={bill.count}
              onChange={(e) => event.handleCountChange(e, index, setBills)}
            />
            <input
              className={styles.tableCell}
              value={bill.price && bill.price.toLocaleString('vi-VN')}
              onChange={(e) => event.handlePriceChange(e, index, setBills)}
            />
            <div className={styles.tableCell}>
              {bill.total && bill.total.toLocaleString('vi-VN')}
            </div>
          </div>
        ))}

        <div className={clsx(styles.tableRow, styles.borderBoldTop)}>
          <div className={clsx(styles.tableCell, styles.tableCellSpanThird)} />

          <div className={clsx(styles.tableCell, 'textBold', 'textLeft')}>
            Tổng tiền
          </div>

          <div className={styles.tableCell}>
            {parseInt(prevTotal).toLocaleString('vi-VN')}
          </div>
        </div>

        <div className={clsx(styles.tableRow, styles.borderNoneTop)}>
          <div className={clsx(styles.tableCell, styles.tableCellSpanThird)} />

          <div className={clsx(styles.tableCell, 'textBold', 'textLeft')}>
            Giảm giá
          </div>

          <input
            className={styles.tableCell}
            value={discount && discount.toLocaleString('vi-VN')}
            onChange={handleDiscountChange}
          />
        </div>

        <div className={clsx(styles.tableRowEqual, styles.borderBoldTop)}>
          <div className={clsx(styles.tableCell, 'flexSpaceBetween')}>
            <div className={clsx('flexAlignCenter', 'textBold')}>
              VAT (
              <input
                type="text"
                className={styles.inputVAT}
                value={VAT}
                onChange={(e) => {
                  const value = parseInt(e.target.value.match(/[0-9]+/g)) || 0;
                  value < 100 ? setVAT(value) : setVAT(100);
                }}
              />
              %):
            </div>
            {tax.toLocaleString('vi-VN')}
          </div>

          <div
            className={clsx(styles.tableCell, 'flexSpaceBetween', 'textBold')}
          >
            <div className="flexAlignCenter">SHIP:</div>
            <input
              className={styles.tableCellInput}
              value={feeDelivery.toLocaleString('vi-VN')}
              onChange={(e) => event.handleFeeDeliveryChange(e, setFeeDelivery)}
            />
          </div>

          <div className={clsx(styles.tableCell, 'textBold', 'textLeft')}>
            Thành tiền
          </div>

          <div className={styles.tableCell}>
            {total.toLocaleString('vi-VN')}
          </div>
        </div>

        <div className={clsx(styles.tableRow, styles.borderBoldTop)}>
          <div className={clsx(styles.tableCell, styles.tableCellSpanThird)} />

          <div className={clsx(styles.tableCell, 'textBold', 'textLeft')}>
            Cọc trước
          </div>

          <div className={clsx(styles.tableCell)}>
            {bill.paid &&
              bill.paid.toLocaleString('vi-VN', {
                styles: 'currency',
              })}
          </div>
        </div>

        <div className={clsx(styles.tableRow, styles.borderNoneTop)}>
          <div className={clsx(styles.tableCell, styles.tableCellSpanThird)} />

          <div className={clsx(styles.tableCell, 'textBold', 'textLeft')}>
            Cần thanh toán
          </div>

          <div className={styles.tableCell}>
            {totalPayment.toLocaleString('vi-VN')}
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <div className={clsx(styles.signWrapper)}>Khách hàng</div>

        <div className={clsx(styles.signWrapper)}>Người giao</div>

        <div className={styles.signWrapper}>
          <p className={styles.signContent}>
            Tp.HCM, ngày {today.getDate()} tháng {today.getMonth() + 1} năm{' '}
            {today.getFullYear()}.
          </p>
          <p className={styles.signContent}>ĐẠI DIỆN CÔNG TY</p>
        </div>
      </div>
    </div>
  );
}

export default RetailBill;
